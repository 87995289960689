import { EVENT_LEAD_GENERATED } from "constants/events";

declare var window: {
  dataLayer: any[];
  mixpanel: any;
};

interface EventLeadGeneratedProps {
  step: string;
}

type EventLeadGeneratedType = {
  fireEvent: Function;
};

const useEventLeadGenerated = (): EventLeadGeneratedType => {
  return {
    fireEvent: (props: EventLeadGeneratedProps, callback?: Function) => {
      if (window) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: EVENT_LEAD_GENERATED, ...props });

        if (window.mixpanel) {
          window.mixpanel.track(
            EVENT_LEAD_GENERATED,
            props,
            undefined,
            callback
          );
        } else {
          callback && callback();
        }
      } else {
        callback && callback();
      }
    },
  };
};

export default useEventLeadGenerated;
