import client from "networking/client";

interface DemoFormResponse {
  demo: boolean;
  // add other fields if necessary
}

export const useSendForm = (): ((
  email: string,
  attributes?: { [name: string]: string }
) => Promise<void>) => {
  return async (email: string, attributes?: any) => {
    return await client.put({
      url: "/public/forms",
      body: JSON.stringify({
        email,
        attributes,
      }),
    });
  };
};

export const useSendDemoForm = (): ((
  email: string,
  attributes?: { [name: string]: string }
) => Promise<DemoFormResponse>) => {
  return async (email: string, attributes?: any) => {
    return await client.put({
      url: "/public/forms-book-demo",
      body: JSON.stringify({
        email,
        attributes,
      }),
    });
  };
};
